<template>
  <div>
    <div v-if="val && !textareaIsVisible">
      <p v-for="(el, i) in convertStringToArray(val)"
         v-bind:key="`${el.name}_${i}`"
         class="mb-0">
        <a v-bind:href="el.url"
           target="_blank">
          {{ el.name }}
        </a>
      </p>
    </div>
    <textarea-autosize class="form-control"
                       v-model="val"
                       v-bind:min-height="30"
                       v-bind:max-height="350"
                       v-bind:disabled="isDisabled"
                       v-if="textareaIsVisible" />
    <button v-on:click.prevent="editTextarea"
            class="btn btn-primary btn-sm mt-2"
            v-bind:disabled="!val">
      {{ editBtnText }}
    </button>
    <p v-if="field.note && textareaIsVisible"
       class="text-sm pt-1 text-muted">{{ field.note }}</p>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Array],
    isDisabled: Boolean,
    field: Object,
  },
  data() {
    return {
      textareaIsVisible: false
    };
  },
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      }
    },
    editBtnText() {
      return this.textareaIsVisible ? 'Просмотреть ссылки' : 'Отредактировать ссылки';
    }
  },
  methods: {
    convertStringToArray(str) {
      let arr = str.split('\n');
      const res = arr.map(el => {
        const fileArr = el.split(' ');
        const url = fileArr.length > 1 ? fileArr[fileArr.length - 1] : fileArr[0];
        if (fileArr.length > 1) fileArr.pop();
        const name = fileArr.length > 1 ? fileArr.join(' ') : fileArr[0];

        return {
          name,
          url
        }
      });
      return res;
    },
    editTextarea() {
      this.textareaIsVisible = !this.textareaIsVisible;
    }
  },
  mounted() {
    if (!this.value) this.textareaIsVisible = true;
  },
  watch: {
    value(val) {
      if (!val) this.textareaIsVisible = true;
    }
  },
}
</script>
