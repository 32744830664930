<template>
  <div class="d-flex align-items-center">
    <input v-bind:id="field.value"
           type="text"
           class="form-control"
           v-model="val"
           v-bind:disabled="phoneIsHidden">
    <a class="btn btn-primary btn-sm ml-2"
       v-on:click.prevent="showPhone"
       v-if="phoneIsHidden && btnText">
      {{ btnText }}
    </a>
  </div>
</template>

<script>
import showClientPhones from '@/lib/showClientPhones.js';
import errorHandler from '@/lib/ErrorHandler';

export default {
  props: {
    value: [String, Array],
    field: Object
  },
  data() {
    return {
      btnText: 'Показать',
      phone: null
    };
  },
  computed: {
    phoneIsHidden() {
      return this.value.includes('***') && !this.phone;
    },
    id() {
      return this.$route.params.id;
    },
    val: {
      get() {
        return this.phone || this.value
      },
      set(v) {
        this.phone = v;
        this.$emit('input', v);
      }
    }
  },
  methods: {
    showPhone() {
      this.btnText = 'Подождите';

      showClientPhones(this.id).then(res => {
        if (res) {
          this.btnText = '';
          this.phone = res[0];
        } else {
          errorHandler.handleError(`Не удалось получить список телефонов для объекта с id ${this.id}`);
          this.btnText = 'Показать';
        }
      }).catch((err) => {
        errorHandler.handleError(err, this.id);
        alert(`Не удалось получить список телефонов для объекта с id ${this.id}`);
      })
    }
  }
}
</script>
