<template>
  <div v-if="value && value.length != 0"
       class="d-flex align-items-center">
    <span v-if="phoneIsHidden">
      {{ value.join(', ') }}
    </span>
    <span v-else>
      <a v-bind:href="`tel:${phone}`"
         v-for="phone in initialVal"
         v-bind:key="phone">
        {{ phone }}
      </a>
    </span>
    <a class="btn btn-primary btn-sm ml-2"
       v-on:click.prevent="showPhone"
       v-if="phoneIsHidden && btnText">
      {{ btnText }}
    </a>
  </div>
</template>

<script>
import showPhones from '@/lib/showPhones.js';
import errorHandler from '@/lib/ErrorHandler';

export default {
  props: {
    value: [String, Array]
  },
  data() {
    return {
      btnText: 'Показать',
      phones: null
    };
  },
  computed: {
    phoneIsHidden() {
      return this.value.some(el => el.includes('***')) && !this.phones;
    },
    id() {
      return this.$route.params.id;
    },
    initialVal() {
      return this.phones || this.value;
    }
  },
  methods: {
    showPhone() {
      this.btnText = 'Подождите';

      showPhones(this.id).then(res => {
        if (res) {
          this.btnText = '';
          this.phones = res;
        } else {
          errorHandler.handleError(`Не удалось получить список телефонов для заявки с id ${this.id}`);
          this.btnText = 'Показать';
        }
      }).catch((err) => {
        errorHandler.handleError(err, this.id);
        alert(`Не удалось получить список телефонов для заявки с id ${this.id}`);
      })
    }
  }
}
</script>
