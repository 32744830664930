<template>
  <div class="form-component-wrap">
    <textarea-autosize
      v-if="formComponent == 'textarea'"
      v-bind:id="field.value"
      class="form-control"
      v-bind:value="controlValue"
      :min-height="30"
      :max-height="350"
      v-bind:disabled="isDisabled"
      v-on:input.native="onInput"
    />
    <div v-if="formComponent == 'textarea_with_hyperlinks'">
      <textarea-with-hyperlinks v-bind:value="controlValue"
                                v-bind:isDisabled="isDisabled"
                                v-on:input="onInputTextareaWithHyperlinks"
                                v-bind:field="field"></textarea-with-hyperlinks>
    </div>
    <div v-if="formComponent == 'claim_phones'">
      <claim-phones v-bind:value="controlValue"
                    v-bind:field="field"></claim-phones>
    </div>
    <div v-if="formComponent == 'client_phones'">
      <client-phones v-bind:value="controlValue"
                     v-bind:field="field"
                     v-on:input="onInputClientPhones"></client-phones>
    </div>
    <select v-if="formComponent == 'dropdown' && !options"
            class="custom-select"
            v-bind:disabled="isDisabled"
            v-bind:value="value"
            v-on:change="onInput">
      <option v-for="item in sourceList"
              v-bind:key="optionValue(item)"
              v-bind:value="optionValue(item)">{{ optionText(item) }}</option>
    </select>
    <select v-if="formComponent == 'dropdown' && options"
            class="custom-select"
            v-bind:disabled="isDisabled"
            v-bind:value="value"
            v-on:change="onInput">
      <option v-for="item in options"
              v-bind:key="selectKey(item.name)"
              v-bind:value="item.name">{{ item.label }}</option>
    </select>
    <input v-if="(formComponent == 'input' || formComponent == 'numeric') && !field.customRender"
           v-bind:id="field.value"
           v-bind:type="field.type || 'text'"
           class="form-control"
           v-bind:disabled="isDisabled"
           v-bind:value="controlValue"
           v-on:input="onInput">
    <datetime v-if="formComponent == 'datetime'"
              v-bind:id="field.value"
              type="datetime"
              input-class="form-control"
              v-bind:phrases="dateTime.phrases"
              v-bind:value="value"
              v-on:input="onInputDateTime"
              v-bind:disabled="isDisabled">
    </datetime>
    <div class="custom-control custom-checkbox"
         v-if="formComponent == 'checkbox'">
      <input type="checkbox"
             class="custom-control-input"
             v-bind:id="name"
             v-bind:disabled="isDisabled"
             v-bind:checked="value"
             v-on:input="onInputCheckbox">
      <label class="custom-control-label" v-bind:for="name">
        {{ label }}
      </label>
      <inherit-val v-if="canBeInherited" v-bind:name="name"></inherit-val>
    </div>
    <div v-bind:class="customRendererFormControlClass"
         v-html="field.customRender(value)"
         v-if="field.customRender"></div>
  </div>
</template>

<script>
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import { Settings } from 'luxon';
import { formatNumber } from '@/lib/formatNumber';
import TextareaWithHyperlinks from '@/components/form/TextareaWithHyperlinks.vue';
import ClaimPhones from '@/components/form/ClaimPhones.vue';
import ClientPhones from '@/components/form/ClientPhones.vue';
import InheritVal from '@/components/partners/InheritVal.vue';

Settings.defaultLocale = 'ru';

export default {
  props: {
    formComponent: {
      type: String,
      default: 'input'
    },
    field: Object,
    value: [String, Number, Array, Object, Boolean],
    isDisabled: Boolean,
    options: [Array, Function],
    name: String,
    label: String,
    canBeInherited: Boolean
  },
  data() {
    return {
      dateTime: {
        phrases: {
          ok: 'Ок',
          cancel: 'Отмена'
        }
      },
    }
  },
  components: {
    Datetime,
    TextareaWithHyperlinks,
    ClaimPhones,
    ClientPhones,
    InheritVal
  },
  computed: {
    sourceList() {
      return this.field.sourceObj || this.field.source;
    },
    controlValue() {
      if (this.field.code == 'RUB') {
        return this.value ? `${formatNumber(this.toFixedIfNecessary(this.value, 2))} ₽` : '';
      }
      else if (this.field.code == '%') {
        return this.value ? `${this.value.toFixed(2)} %` : '';
      } else if (!this.value && this.formComponent == 'textarea') {
        return '';
      } else {
        return this.field.customFormat ? this.field.customFormat(this.value) : this.value;
      }
    },
    formComponentClass() {
      return {
        'chk-input-wrap': this.formComponent == 'checkbox'
      }
    },
    customRendererFormControlClass() {
      return [
        'form-control disabled',
        {
          'h-auto': this.field.formComponent == 'pre'
        }
      ]
    }
  },
  methods: {
    onInput(e) {
      const v = this.field.type == 'number' && e.target.value == '' ? null : e.target.value;
      this.$emit('input', v);
    },
    onInputCheckbox(e) {
      this.$emit('input', e.target.checked);
    },
    onInputDateTime(e) {
      this.$emit('input', e);
    },
    onInputTextareaWithHyperlinks(e) {
      this.$emit('input', e);
    },
    onInputClientPhones(e) {
      this.$emit('input', e);
    },
    optionValue(item) {
      return typeof item == 'string' ? item : item.value;
    },
    optionText(item) {
      return typeof item == 'string' ? item : item.text;
    },
    toFixedIfNecessary(value, dp){
      return +parseFloat(value).toFixed(dp);
    },
    getValue(v) {
      return this.field.type == 'numeric' ? this.toFixedIfNecessary(v, 2) : v;
    },
    selectKey(n) {
      return this.name ? `${this.name}_${n}` : n;
    }
  }
}
</script>
