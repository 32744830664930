<template>
  <span class="ml-1" v-if="isVisible">
    <a class="link-primary" v-if="isInherited" v-on:click="inheritVal">&#10003;&nbsp;унаследовано</a>
    <a class="link-primary" v-else v-on:click="inheritVal">унаследовать</a>
  </span>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['name'],
  data() {
    return {
      isInherited: false
    }
  },
  computed: {
    ...mapGetters({
      inheritedFields: 'partners/getInheritedFields',
      parentId: 'partners/getParentId'
    }),

    isVisible() {
      return this.parentId != null || this.$route.query.copyFromId != null;
    }
  },
  methods: {
    inheritVal() {
      this.isInherited = !this.isInherited;

      if (!this.isInherited) return this.$store.commit('partners/removeInheritedFields', this.name);

      this.$store.commit('partners/addInheritedFields', this.name);
    }
  },
  created() {
    this.isInherited = this.inheritedFields.includes(this.name);
  }
};
</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
  font-weight: 700;
}
</style>
